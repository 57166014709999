<template>
  <v-tooltip bottom color="secondary">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :icon="buttonIcon"
             :small="buttonSmall"
             :class="buttonClass"
             :style="buttonStyle"
             depressed
             tag="a"
             :href="href"
             v-bind="attrs"
             v-on="on"
             @click.stop
             :target="target">
        <v-icon
            :size="iconSize" :color="iconColor" :class="iconClass" :style="iconStyle">
          mdi-youtube
        </v-icon>
        <a href="" ></a>
      </v-btn>
    </template>
    <span>Перегляньте відео-інструкцію</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "YouTubeButton",
  props: {
    href: {
      type: String,
      default: 'https://www.youtube.com/watch?v=KtKz5C3tFNQ&list=PLegjYX_k1JkpjQLUutVlVsNH6G9SFS6V7'
    },
    target: {
      type: String,
      default: '_blank'
    },
    iconSize: {
      type: Number,
      default: 24
    },
    iconColor: {
      type: String,
      default: 'grey darken-1'
    },
    iconClass: {
      type: String,
      default: ''
    },
    iconStyle: {
      type: String,
      default: ''
    },
    buttonClass: {
      type: String,
      default: ''
    },
    buttonStyle: {
      type: String,
      default: ''
    },
    buttonSmall: {
      type: Boolean,
      default: false
    },
    buttonIcon: {
      type: Boolean,
      default: true
    },
  }
}
</script>

<style scoped>

</style>